<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline title="电子保单"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <template #header="params">
        <div class="search_box">
          <el-form :inline="true" :model="searchData" size="small" label-position="left">

            <el-form-item label="报价单号">
              <el-input v-model="searchData.inquiryNo" placeholder="报价单号" clearable></el-input>
            </el-form-item>
            <el-form-item label="投保类型">
              <el-select v-model="searchData.businessType" placeholder="投保类型" clearable>
                <el-option label="新保" value="1"></el-option>
                <el-option label="续保" value="2"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="险种类型">
              <el-select v-model="searchData.insuranceType" placeholder="险种类型" clearable>
                <el-option label="交强险" value="1"></el-option>
                <el-option label="商业险" value="2"></el-option>
                <el-option label="其他" value="5"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="手机号">
              <el-input v-model="searchData.mobile" placeholder="手机号" clearable></el-input>
            </el-form-item>
            <el-form-item label="投保人">
              <el-input v-model="searchData.policyholder" placeholder="投保人" clearable></el-input>
            </el-form-item>
            <el-form-item label="保司">
              <el-input v-model="searchData.orgName" placeholder="保司" clearable></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="pageHandler(params, 1)">查询</el-button>
              <el-button @click="pageHandler(params, 1,'reset')" type="primary">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <el-table-column label="流水号" fixed="left" prop="epNo" min-width="150"></el-table-column>
      <el-table-column label="报价编号" prop="inquiryNo" min-width="150"></el-table-column>
      <el-table-column label="投保人" prop="policyholder" min-width="150"></el-table-column>
      <el-table-column label="投保人手机号" prop="mobile" min-width="150"></el-table-column>
      <el-table-column label="投保类型" prop="businessType">
        <template slot-scope="scope">
          {{scope.row.businessType | businessTypeFilter}}
        </template>
      </el-table-column>
      <el-table-column label="车牌" prop="licensePlate" min-width="150"></el-table-column>
      <el-table-column label="险种类型" prop="insuranceType">
        <template slot-scope="scope">
          {{scope.row.insuranceType | insuranceTypeFilter}}
        </template>
      </el-table-column>
      <el-table-column label="保司" prop="orgName" min-width="150"></el-table-column>
      <el-table-column label="地区" prop="regionDesc" min-width="150"></el-table-column>
      <el-table-column label="创建时间" prop="ctime" min-width="150"></el-table-column>
      <el-table-column label="操作" fixed="right" prop="" width="120">
        <template slot-scope="scope">
            <el-link type="primary" @click="view(scope.row)">查看保单</el-link>
        </template>
      </el-table-column>
    </my-table>

    <el-drawer title="查看保单附件" :before-close="previewPdfClose" :visible.sync="previewPdfDialogVisible" direction="rtl"
               ref="drawer" size="60%">
      <pdf :src="previewPdf" v-if="previewPdf" style="width: 100%;height: 100%"></pdf>
    </el-drawer>
  </div>
</template>

<script>
/* eslint-disable */
import FileUpload from "@/components/scope/file-upload.vue";
import Template from "@/views/dashboard/template.vue";
import pdf from "vue-pdf"
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Template, FileUpload ,pdf},
  data() {
    //这里存放数据
    return {
      listData: [],
      // 搜索数据
      searchData: {
        insuranceType: null,
        inquiryNo: null,
        businessType: null,
        mobile: null,
        orgName: null,
        policyholder: null
      },
      listLoading: false,
      pageData: {
        num: 1,
        size: 5,
        total: 0
      },
      previewPdf: '',
      previewPdfDialogVisible: false,
    };
  },
  filters: {
    businessTypeFilter(val){
      switch (val) {
        case 1:
          return "新保";
        case 2:
          return "续保";
      }
      return "-"
    },
    insuranceTypeFilter(val){
      switch (val) {
        case 1:
          return "交强险";
        case 2:
          return "商业险";
        case 5:
          return "其他";
      }
      return "-"
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num, type) {
      if(type == 'reset'){
        this.resetSearchData();
      }
      this.getDataList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        insuranceType: null,
        inquiryNo: null,
        businessType: null,
        mobile: null,
        orgName: null,
        policyholder: null
      }
    },

    getDataList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('insurance/getElectronicPolicyList',{
        ...this.searchData,
        pageNum,
        pageSize,
      }).then(res => {
        if(res.code == 0){
          console.log(res)
          this.listData = res.data.list;
          params.render(res.data.total);
        }
        this.listLoading = false;
      })
    },
    view(row){
      console.log(row)
      // window.open('http://view.xdocin.com/view?src=' +row.pkl);
      this.previewPdf = row.pkl;
      this.previewPdfDialogVisible = true;
    },
    previewPdfClose(){
      this.previewPdfDialogVisible = false;
      this.previewPdf = '';
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.page_box {
  padding: 0 20px;
  .search_box {
    background: #fff;
    padding: 20px;
    margin-top: 20px;
  }
  .table_nav_bar{
    width: 100%;
    background: #fff;
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    .el-button{
      margin-right: 10px;
    }
  }
  .table_row_rule{
    word-break: break-all; /** 换行，长单词会分成两行显示 **/
    text-overflow: ellipsis;/** 隐藏文本省略号 ... **/
    display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
    -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
    -webkit-line-clamp: 1; /** 显示的行数 **/
    overflow: hidden;  /** 隐藏超出的内容 **/;
  }
}
.handle_btn{
  padding: 0;
}
.channel_item{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  >.el-input:nth-child(1){
    width: 260px;
  }
}
.channel_item:last-child{
  margin: 0;
}
.popularize_list_qrcode{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qrcode_img_src{
  position: absolute;
  top: -10000%;
  z-index: -10000;
}
.search_item{
  display: flex;
  align-items: center;
  >div:first-child{
    flex-shrink: 0;
  }
}
.insurance_logo_link{
  width: 72px;
  height: 72px;
  display: block;
}
.insurance_dialog{
  ::v-deep .el-dialog__body{
    padding: 10px 20px !important;
  }
  .el-input{
    width: 50%;
  }
  .insurance_dialog_module{
    .dialog_module_title{
      font-weight: 500;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .title_line{
        width: 4px;
        height: 16px;
        background: #409EFF;
        margin-right: 5px;
        border-radius: 1px;
      }
    }
    .popup_table{
      .el-input{
        width: 100%;
      }
    }
  }
}
::v-deep .el-table__header thead tr th:first-of-type, .el-table__body tbody tr td:first-of-type{
  padding: 0 !important;
}
::v-deep .el-table__body tbody tr td:first-of-type {
  padding-left: 0px !important;
}
</style>
